import React from 'react'
import Toggler from './Toggler'
import styled from '@emotion/styled';
const Toggle = ({ darkMode, handleClick }) => {
    const Sun = styled.div`
    position: fixed;
    bottom: 30px;
    right: 30px;
    cursor: pointer;
    pointer-events: all;
    z-index: 1;
    `;
    return (
        <Sun>
            <Toggler darkMode={darkMode} handleClick={handleClick} />
        </Sun>
  )
}

export default Toggle
