import React, { useEffect } from 'react';
import anime from 'animejs';
import './cursor.scss'
const SparkComponent = () => {
  useEffect(() => {
    const container = document.querySelector('.anime-container');
    const sparks = [];
    let sparksIndex = 0;

    const lerp = (p1, p2, t) => {
      return p1 + (p2 - p1) * t;
    };

    const ease = {
      linear: (t) => t,
      inQuad: (t) => t * t,
      outQuad: (t) => t * (2 - t),
      inOutQuad: (t) => (t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t),
      inCubic: (t) => t * t * t,
      outCubic: (t) => --t * t * t + 1,
      inOutCubic: (t) =>
        t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1,
      inQuart: (t) => t * t * t * t,
      outQuart: (t) => 1 - --t * t * t * t,
      inOutQuart: (t) =>
        t < 0.5 ? 8 * t * t * t * t : 1 - 8 * --t * t * t * t,
      inQuint: (t) => t * t * t * t * t,
      outQuint: (t) => 1 + --t * t * t * t * t,
      inOutQuint: (t) =>
        t < 0.5 ? 16 * t * t * t * t * t : 1 + 16 * --t * t * t * t * t
    };

    const circularRandom = (r) => {
      r = r * Math.sqrt(Math.abs(Math.random() - Math.random()));
      const theta = Math.random() * 2 * Math.PI;
      const x = r * Math.cos(theta);
      const y = r * Math.sin(theta);
      return { x, y, r };
    };

    for (let i = 0; i <= 100; i += 1) {
      const spark = { els: [] };

      for (let j = 0; j < 6; j++) {
        const dot = document.createElement('div');
        dot.classList.add('dot');
        container.appendChild(dot);
        spark.els.push(dot);

        const particleSize = anime.random(5, 20);
        const sparkRadius = 20;
        const { x, y, r } = circularRandom(sparkRadius);

        dot.style.width = lerp(
          particleSize,
          1,
          ease.outQuad(r / sparkRadius)
        ) + 'px';
        dot.style.height = lerp(
          particleSize,
          1,
          ease.outQuad(r / sparkRadius)
        ) + 'px';
        dot.style.opacity = '0';
        dot.style.transform = `translateX(${x}px) translateY(${y}px)`;
      }

      spark.anime = anime({
        targets: spark.els,
        loop: false,
        easing: 'linear',
        autoplay: false,
        delay: anime.stagger(8),
        opacity: [
          { value: 0, duration: 0 },
          { value: 1, duration: 40 },
          {
            value: 0,
            duration: () => anime.random(500, 800)
          }
        ],
        width: { value: 2, duration: 500 },
        height: { value: 2, duration: 500 },
        translateX: {
          value: () => anime.random(-30, 30),
          duration: 800
        },
        translateY: {
          value: () => anime.random(-30, 30),
          duration: 800
        }
      });

      sparks.push(spark);
    }

    const handleMouseMove = (e) => {
      anime.set(sparks[sparksIndex].els, {
        left: e.pageX,
        top: e.pageY
      });
      sparks[sparksIndex].anime.restart();
      sparksIndex++;
      if (sparksIndex === sparks.length) sparksIndex = 0;
    };

    document.addEventListener('mousemove', handleMouseMove);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return <div className="anime-container"></div>;
};

export default SparkComponent;
