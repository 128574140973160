import React, { useState } from 'react';
import Style from './Navbar.module.scss';
import { Link, useLocation } from 'react-router-dom';
import { Box } from '@mui/material';
import { info } from '../assets/info/Info';
import Toggle from './home/Toggle';
import initials from './../assets/img/initials.png'
import { FaBars } from 'react-icons/fa';
const links = [
  {
    name: 'Home',
    to: '/',
    active: 'home',
  },
  {
    name: 'Portfolio',
    to: '/portfolio',
    active: 'portfolio',
  },
  {
    name: info.initials,
    type: initials,
    to: '/',
    active: 'home',
  },
  {
    name: 'Certificates',
    to: '/certificates',
    active: 'certificates',
  },
  {
    name: 'About',
    to: '/about',
    active: 'about',
  }
];

export default function Navbar({darkMode, handleClick}) {
  const location = useLocation();
  const [active, setActive] = useState(
    location.pathname === '/' ? 'home' : location.pathname.slice(1, location.pathname.length),
  );
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);

  const handleMobileNavToggle = () => {
    setIsMobileNavOpen(!isMobileNavOpen);
  };

  return (
    <>
      <Box component={'nav'} width={'100%'}>
        {/* Mobile Navbar Toggle */}
        <Box
          display={{ xs: 'block', md: 'none' }} // Show the icon only on mobile (xs) screens
          fontSize={'1.5rem'}
          onClick={handleMobileNavToggle}
          className={Style.mobileToggle}
        >
          <FaBars style={{margin:"1rem", zIndex:'1'}}/>
        </Box>

        <Toggle darkMode={darkMode} handleClick={handleClick} />

        {/* Navbar Items */}
        <Box
          component={'ul'}
          display={{ xs: isMobileNavOpen ? 'flex' : 'none', md: 'flex' }} // Show/hide the navbar items based on mobileNavOpen state
          flexDirection={{ xs: 'column', md: 'row' }}
          justifyContent={'center'}
          alignItems={'center'}
          padding={'10px'}
          fontSize={'1.5rem'}
          fontWeight={'600'}
          marginBottom={0}
          gap={{ xs: '2rem', md: '4rem' }}
        >
          {links.map((link, index) => (
            <Box
              key={index}
              component={'li'}
              width={100}
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
              className={link.active === active && !link.type && Style.active}
              sx={{ borderImageSource: info.gradient }}
            >
              <Link
                to={link.to}
                onClick={() => {
                  setActive(link.active);
                  setIsMobileNavOpen(false); // Close the mobile nav on link click
                }}
                className={Style.link}
              >
                {!link.type && <p>{link.name}</p>}
                {link.type && <img width={'80%'} src={initials} alt="ini" />}
              </Link>
            </Box>
          ))}
        </Box>
      </Box>
    </>
  );
}
