import React, { useState, useEffect } from 'react';
import './cursor1.scss';
const Cursor = () => {
  const [cursorStyle, setCursorStyle] = useState({ top: '10px', left: '-10px' });
  const [isExpanding, setIsExpanding] = useState(false);

  const handleMouseMove = (e) => {
    setCursorStyle({ top: e.pageY - 20, left: e.pageX - 20 });
  };

  const handleClick = () => {
    setIsExpanding(true);

    setTimeout(() => {
      setIsExpanding(false);
    }, 500);
  };

  useEffect(() => {
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('click', handleClick);
    };
  }, []);

  return (
    <>
      <div
        className={`cursor ${isExpanding ? 'expand' : ''}`}
        style={{ ...cursorStyle }}
      />
      {/* Add any other content here */}
    </>
  );
};

export default Cursor;
