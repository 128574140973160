import self from '../img/self.png';
import { colors } from '../colors/mainGradient';
import ems from '../../assets/img/ems.png'
import keeper from '../../assets/img/keeper.png'
import crud from '../../assets/img/crudify.png'
import amz from '../../assets/img/amz.png'
import yt from '../../assets/img/yt.png'
import webcam from '../../assets/img/webcam.png'
import weather from '../../assets/img/weather.png'
export const info = {
  firstName: 'Adarsh',
  lastName: 'Gupta',
  initials: 'AG', // the example uses first and last, but feel free to use three or more if you like.
  position: 'a Full Stack Developer',
  selfPortrait: self, // don't change this unless you want to name your self-portrait in the "img" folder something else!
  gradient: `-webkit-linear-gradient(135deg, ${colors})`, // don't change this either
  baseColor: colors[0],
  miniBio: [
    {
      emoji: '💻',
      text: 'Full Stack Developer at Kolkata',
    },
    {
      emoji: '📧',
      text: 'gadarsh747@gmail.com',
    },
  ],
  socials: [
    {
      link: 'https://drive.google.com/file/d/1pYCm6YiXjnzlaK_WSbNDwBUfJu0jGbAl/view?usp=sharing',
      icon: 'fa fa-file',
      label: 'resume',
    },
    {
      link: 'https://www.instagram.com/adarshmaximal/',
      icon: 'fa fa-instagram',
      label: 'instagram',
    },
    {
      link: 'https://github.com/adarshgupta404/',
      icon: 'fa fa-github',
      label: 'github',
    },
    {
      link: 'https://www.linkedin.com/in/adarshgupta404/',
      icon: 'fa fa-linkedin',
      label: 'linkedin',
    },
    {
      link: 'https://twitter.com/adarshgupta404',
      icon: 'fa fa-twitter',
      label: 'twitter',
    },
  ],
  bio: "Hello! I am Adarsh Gupta. I am a pre-final year B.Tech student at Netaji Subhash Engineering College, specializing in Computer Science and Engineering. As a full-stack web developer, I have expertise in data science and machine learning. I enjoy staying updated with new technologies and frameworks, leveraging my knowledge in DS and ML to create innovative solutions. My passion for travel fuels my curiosity and brings a fresh perspective to problem-solving.",
  skills: {
    // you dont havew to strictly follow the 'languages', 'frameworks', 'databases', 'cloudServices', and 'tools' categories. You can add your own if you'd like.
    // but the structure should be the same, an array of strings and the key should be one word, no spaces.
    languages: ['JavaScript', 'C/C++', 'Python', 'HTML/CSS', 'JAVA'],
    frameworks: [
      'React',
      'Redux',
      'Tailwindcss',
      'Material UI',
      'Bootstrap/jQuery',
      'Express',
      'NestJS',
      'Three.js'
    ],
    databases: ['MySQl', 'MongoDB', 'PostgreSQL', 'Firebase'],
    cloudServices: ['Vercel', 'Render', 'Heroku', 'Netlify'],
    tools: [
      'Git',
      'Vite.js',
      'Postman',
      'npm',
      'yarn'
    ],
  },
  hobbies: [
    {
      label: 'Video Games',
      emoji: '🎮',
    },
    {
      label: 'Anime',
      emoji: '🍥🍜🦊',
    },
    {
      label: 'Coding',
      emoji: '👨‍💻',
    },
    {
      label: 'Cooking',
      emoji: '🍳',
    },
    {
      label: 'Traveling',
      emoji: '✈️',
    },
    {
      label: 'Guitar',
      emoji: '🎸',
    }
    // Same as above, change the emojis to match / relate to your hobbies or interests.
    // You can also remove the emojis if you'd like, I just think they look cute :P
  ],
  portfolio: [
    // This is where your portfolio projects will be detailed
    //   for the null ones, the button will not show up
    {
      title: 'Amazon Clone',
      live: 'https://amazon-clone-adarsh.vercel.app', //this should be a link to the live version of your project, think github pages, netlify, heroku, etc. Or your own domain, if you have it.
      source: 'https://github.com/adarshgupta404/Amazon-Clone', // this should be a link to the **repository** of the project, where the code is hosted.
      image: amz,
    },
    {
      title: 'Employee Management System',
      live: 'https://employee-m-system.vercel.app', //this should be a link to the live version of your project, think github pages, netlify, heroku, etc. Or your own domain, if you have it.
      source: 'https://github.com/adarshgupta404/Employee-Management-System', // this should be a link to the **repository** of the project, where the code is hosted.
      image: ems,
    },
    {
      title: 'YouTube Transcript Summarizer',
      live: 'https://youtube-transcript-summarizer.vercel.app', //this should be a link to the live version of your project, think github pages, netlify, heroku, etc. Or your own domain, if you have it.
      source: 'https://github.com/adarshgupta404/YT-Transcript-Summarizer', // this should be a link to the **repository** of the project, where the code is hosted.
      image: yt,
    },
    {
      title: 'Crudify - Next.js CRUD Blog App',
      live: 'https://crudifyme.vercel.app',
      source: 'https://github.com/adarshgupta404/Crudifyme',
      image: crud,
    },
    {
      title: 'Web-Cam Chat',
      live: 'https://web-cam-chat-app.vercel.app',
      source: 'https://github.com/adarshgupta404/Web-Cam-Chat-App',
      image: webcam,
    },
    {
      title: 'Keeper Weeper',
      live: 'https://keeper-app-coral-eight.vercel.app',
      source: 'https://github.com/adarshgupta404/keeper-app',
      image: keeper,
    },
    {
      title: 'Weather App',
      live: 'https://weather-app-five-gules.vercel.app/',
      source: 'https://github.com/adarshgupta404/Weather-App',
      image: weather,
    },
  ],
};
