import React, { useState } from 'react';
import Cursor from './cursor1';
import SparkComponent from './cursor';
import { FiMousePointer } from 'react-icons/fi';
const CursorSwitcher = () => {
  const [useSparkCursor, setUseSparkCursor] = useState(false);

  const handleButtonClick = () => {
    setUseSparkCursor((prevValue) => !prevValue);
  };

  return (
    <div>
      {useSparkCursor ? <SparkComponent /> : <Cursor />}
      <FiMousePointer style={{'position':'absolute', 'margin':'20px  10px',transform: 'rotate(15deg)', 'right':'0px'}} size={32} onClick={handleButtonClick}>
        {useSparkCursor ? 'Use Cursor' : 'Use Spark Cursor'}
      </FiMousePointer>
    </div>
  );
};

export default CursorSwitcher;
