import React from 'react'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActions, CardActionArea } from '@mui/material';
import { Box, Grid } from '@mui/material';
import cyber from "../../assets/img/cyber.jpg"
import philo from "../../assets/img/philo.jpg"
import python from "../../assets/img/python.jpg"
export default function Certificates(){

  return (
    <Box>
      <Grid container display={'flex'} justifyContent={'center'}>
      <Card style={{margin:'3em'}} sx={{ maxWidth: 500 }}>
          <CardActionArea>
            <CardMedia
              component="img"
              height="100%"
              image="https://udemy-certificate.s3.amazonaws.com/image/UC-5c35ce56-770e-47a3-b0f8-ee0ae2f8368c.jpg?v=1678002312000"
              target="_blank"
              href="https://www.udemy.com/certificate/UC-5c35ce56-770e-47a3-b0f8-ee0ae2f8368c/"
              alt="webdev"
            />
            <CardContent style={{padding:"0.3rem"}}>
              <Typography fontFamily="Times New Roman" gutterBottom fontSize="1.2rem"  component="div">
              <a style={{ textDecoration: 'none', color: 'black'}} target="_blank" href="https://www.udemy.com/certificate/UC-5c35ce56-770e-47a3-b0f8-ee0ae2f8368c/">The Complete 2023 Web Development Bootcamp</a>
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
        <Card style={{margin:'3em'}} sx={{ maxWidth: 500 }}>
          <CardActionArea>
            <CardMedia
              component="img"
              height="100%"
              image={python}
              target="_blank"
              href="https://www.coursera.org/account/accomplishments/verify/EUQVD7QUWYVL"
              alt="python"
            />
            <CardContent style={{padding:"0.3rem"}}>
              <Typography fontFamily="Times New Roman" gutterBottom fontSize="1.2rem"  component="div">
              <a style={{ textDecoration: 'none', color: 'black'}} target="_blank" href="https://www.coursera.org/account/accomplishments/verify/EUQVD7QUWYVL">Python Data Structures</a>
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
        <Card style={{margin:'3em'}} sx={{ maxWidth: 500 }}>
          <CardActionArea>
            <CardMedia
              component="img"
              height="100%"
              image={cyber}
              target="_blank"
              href="https://www.coursera.org/account/accomplishments/certificate/RN9HXLWJ6KKW"
              alt="webdev"
            />
            <CardContent style={{padding:"0.3rem"}}>
              <Typography fontFamily="Times New Roman" gutterBottom fontSize="1.2rem"  component="div">
              <a style={{ textDecoration: 'none', color: 'black'}} target="_blank" href="https://www.coursera.org/account/accomplishments/verify/RN9HXLWJ6KKW">Cybersecurity for Everyone</a>
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
        <Card style={{margin:'3em'}} sx={{ maxWidth: 500 }}>
          <CardActionArea>
            <CardMedia
              component="img"
              height="100%"
              image={philo}
              target="_blank"
              href="https://www.coursera.org/account/accomplishments/verify/6793QZHMAYFL"
              alt="webdev"
            />
            <CardContent style={{padding:"0.3rem"}}>
              <Typography fontFamily="Times New Roman" gutterBottom fontSize="1.2rem"  component="div">
              <a style={{ textDecoration: 'none', color: 'black'}} target="_blank" href="https://www.coursera.org/account/accomplishments/verify/6793QZHMAYFL">Introduction to Philosophy</a>
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
    </Box>
  )
}
