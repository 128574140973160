import React from 'react'
import { useState } from 'react';
import './btn.scss';
import Canva from './Model'
const Dawaebutton = () => {
    const [clicked, setClicked] = useState(false);
    const handleclick = () => {
        setClicked(true)
    }
    return (
        <>
            {   clicked ?
                <Canva />
                :
                <button onClick={handleclick} className="button yellow">
                    Do you Know<br />DA Wae?
                </button>
            }
        </>

    )
}

export default Dawaebutton
