import { Suspense, useRef } from 'react';
import { useFrame } from 'react-three-fiber';
import { Canvas } from '@react-three/fiber'
import { OrbitControls, useGLTF } from '@react-three/drei'
import './btn.scss';

export function Dawae(props) {
  const { nodes, materials } = useGLTF('/dawae.gltf')
  const groupRef = useRef()

  useFrame(() => {
    groupRef.current.rotation.y += 0.002 // Adjust the rotation speed as per your preference
  })

  return (
    <group ref={groupRef} {...props} dispose={null}>
      <group position={[0, 0.002, -0.012]} rotation={[-1.566, 0, 0]}>
        <primitive object={nodes.Armature_rootJoint} />
        <mesh geometry={nodes.Knuckles_0.geometry} material={materials['Knux.001']} />
      </group>
    </group>
  )
}

function Canva() {
  return (
    <div className="canvas">
      <Canvas camera={{ position: [0, 2, 6] }}>
        <Suspense fallback={null}>
          <ambientLight />
          <spotLight intensity={0.6}
            angle={0.1}
            penumbra={1}
            position={[10, 10, 10]}
            castShadow />
          <Dawae position={[0, -2.25, 0]} scale={[1, 1, 1.2]} />
          <OrbitControls enablePan={true}
            enableZoom={true}
            enableRotate={true} />
        </Suspense>
      </Canvas>
    </div>
  );
}

export default Canva;
